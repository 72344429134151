::-moz-selection {
  color: #FFF;
  background: var(--color-primary-darker);
}
::selection {
  color: #FFF;
  background: var(--color-primary-darker);
}

body {
  height: 100%;
}

body * input:focus {
  border:1px solid var(--color-primary-darker) !important;
}

.grecaptcha-badge { 
  visibility: hidden;
}